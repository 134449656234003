<template>
  <contractList />
</template>

<script>
import contractList from '@/components/contractList';

export default {
  components: { contractList },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
