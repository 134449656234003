<template>
  <div class="report-box">
    <div class="report-info report-header">
      <div>
        <span class="clinic-name">
          {{ info.patientName }}
        </span>
        <span style="margin-left: 8px">
          <i class="iconfont icon-nan" v-if="$getGender(info.gender) === '男'" style="color:#10aeff" />
          <i class="iconfont icon-nv" v-if="$getGender(info.gender) === '女'" style="color:#fb7373" />
        </span>
      </div>

      <span class="doc-name">{{ info.updateTime }}</span>
    </div>
    <div class="report-info report-project" @click="toDetail(info)">
      <span>
        {{ info.goodsName }}
      </span>
      <span class="report-read">
        {{ checkOrderState.getValueByKey(info.status) }}
        <icon name="arrow" v-if="hasDetail(info)" />
      </span>
    </div>
    <!-- <div class="report-code">{{ info.deviceNum }}</div> -->
    <div class="report-foot">
      <div class="foot-line">
        <span class="patient-name" v-if="!showClinicName">医生：{{ info.createBy }}</span>
        <span class="patient-name" v-if="showClinicName">{{ info.clinicName }}</span>
        <!-- <span class="clinic-name">{{ info.deviceNum }}</span> -->
        <div class="right-panel">
          <span class="default-btn" @click="toTest(info)" v-if="showTest && hasDelete(info)">
            做检测
          </span>
          <span class="delete-btn" @click="deleteCheckOrder(info)" v-if="showDelete && hasDelete(info)">
            取消检测
          </span>
        </div>
      </div>
      <div class="foot-line">
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from 'vant';
import services from '@/service';
import { mapGetters } from 'vuex';
import { getUserType } from '@/lib/utils';
import { isMiniProgram, AX_XD } from '@/global';
import wechat from '@/wechat';

export default {
  components: { Icon },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      colorEnum: {
        检测中: '#808084',
        查看报告: '#24d270'
      }
    };
  },
  computed: {
    ...mapGetters(['checkOrderState']),
    userType() {
      return getUserType();
    },
    showDelete() {
      return this.userType === 'clinic';
    },
    showClinicName() {
      return this.userType === 'admin';
    },
    canPay() {
      return this.userType === 'patient' && this.info.paymentMode === '2' && this.info.payStatus === '0';
    },
    showTest() {
      return [AX_XD].includes(this.info.deviceType) && this.userType === 'clinic';
    }
  },
  methods: {
    hasDelete(item) {
      const status = [item.status];
      if (status.includes('0')) {
        return true;
      } else {
        return false;
      }
    },
    deleteCheckOrder(item) {
      this.$dialog
        .confirm({
          title: '提示',
          message: '请确认是否取消检测订单？'
        })
        .then(async () => {
          await services.clinic.deleteCheckOrder(item.checkOrderId);
          this.$toast('取消成功');
          item.status = this.checkOrderState.getKeyByValue('已取消');
        });
    },
    toTest() {
      if (!isMiniProgram) {
        return this.$toast('请在小程序端操作');
      }
      const data = {
        checkCode: this.info.submitCode
      };
      wechat.$wx.miniProgram.navigateTo({ url: `/pages/electrocardiogramInit/index?data=${JSON.stringify(data)}` });
    },
    hasDetail(item) {
      return ['2', '4', '6'].includes(item.status);
    },
    toDetail(item) {
      if (this.canPay) {
        this.$router.push({
          path: `/${this.userType}-report-pay?id=${item.checkOrderId}`
        });
      } else {
        if (!this.hasDetail(item)) return;
        this.$router.push({
          path: `/${this.userType}-report-detail?id=${item.checkOrderDetailId}`
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.report-box {
  background: #fff;
  margin: $tb $lr;
  border-radius: $radius;
  box-shadow: $box-shadow1;
  font-size: $size2;
  line-height: 26px;
  color: $black1;
  // height: 300px;

  .report-header {
    font-size: $size6;
    margin-bottom: $tb;
    .clinic-name {
      background: $btnbg;
      color: #fff;
      padding: 4px $lr;
      border-radius: $radius 0 $radius 0;
    }
    .doc-name {
      color: $gray2;
      padding: 0 $lr;
      position: relative;
      top: 2px;
    }
  }
  .report-name {
    font-size: $size1;
    font-weight: 600;
    padding: $tb $lr;
    color: $gray2;
  }
  .report-code {
    font-size: $size6;
    padding: 0 $lr;
    color: $gray2;
    text-align: right;
  }
  .report-info {
    @include flexCenter(row);
    justify-content: space-between;

    .report-time {
      .clinic-name {
        flex: 1;
        @include hiddenText();
        font-size: $size6;
      }
    }
  }

  .report-project {
    padding: 0 $lr $tb;

    .report-read {
      font-size: $size4;
      color: $appcolor;
      font-weight: 500;
    }
  }

  .report-foot {
    border-top: 1px solid $bordercolor;
    font-size: $size6;
    color: $gray2;
    padding: 4px $lr;

    .foot-line {
      @include flex(row);
      align-items: center;
      justify-content: space-between;
    }

    .delete-btn {
      display: inline-block;
      border: 1px solid $red1;
      color: $red1;
      border-radius: 4px;
      padding: 0px 10px;
      margin: 2px 0;
    }

    .default-btn {
      display: inline-block;
      border: 1px solid $appcolor;
      color: $appcolor;
      border-radius: 4px;
      padding: 0px 10px;
      margin: 2px 0;
    }

    .right-panel {
      span {
        margin-left: $lr;
      }
    }
  }
}
</style>
