import request from '@/lib/request';
import { userTypeAll } from '@/enum/index';
const userType = userTypeAll.getKeyByValue('合伙人端');

export function sendSms(mobile) {
  return request.get(`/auth/userlogin/sms/${mobile}/${userType}`);
}

export function smsLogin(params) {
  return request.post(`/auth/userlogin/smslogin`, { ...params, userType });
}

export function userLogin(params) {
  return request.post('/user/login', params);
}

export function getClinicList(params) {
  return request.get(`/partner/clinic/list`, { params });
}

export function getDevelopClinicList(params) {
  return request.get(`/partner/clinic/developing`, { params });
}

export function getBindClinicList(params) {
  return request.get(`/partner/clinic/allClinic`, { params });
}

export function getDeviceList() {
  return request.get(`/partner/clinic/device/rel/listAllDevices`);
}

export function bindClinicDevice(params) {
  return request.post(`/partner/clinic/device/rel/bindClinicDevice`, params);
}

export function clinicAdd(params) {
  return request.put(`/partner/clinic/add`, params);
}

export function getMyTeam(params) {
  return request.get(`/partner/sales/myTeam`, { params });
}

export function getSalesInfo(id) {
  return request.get(`/partner/sales/${id}`);
}

export function editSalesInfo(params) {
  return request.put(`/partner/sales`, params);
}

export function addSalesInfo(params) {
  return request.post(`/partner/sales`, params);
}

export function addSalesInvite(params) {
  return request.post(`/partner/sales/invite`, params);
}

export function getPartnerInfo() {
  return request.get(`/partner/user/getInfo`);
}

export function changeAvatar(params) {
  return request.post(`/partner/user/avatar?avatar=${params.avatar}`);
}

export function getPartnerScore() {
  return request.get(`/partner/index/score`);
}

export function getRankClinic(params) {
  return request.get(`/partner/index/rank/clinic`, { params });
}

export function getRankSales() {
  return request.get(`/partner/index/rank/sales`);
}

export function salesDeviceList(params) {
  return request.get(`/partner/clinic/device/rel/salesDeviceList`, { params });
}

export function getClinicInfo(id) {
  return request.get(`/partner/clinic/getInfo/${id}`);
}

export function getDevelopingClinicInfo(hisId) {
  return request.get(`/partner/clinic/developing/${hisId}`);
}

export function delClinicDevice(id) {
  return request.delete(`/partner/clinic/device/rel/delClinicDevice/${id}`);
}

export function changeClinicDevices(params) {
  return request.post(`/partner/clinic/device/rel/changeClinicDevices`, params);
}

export function clinicWithinRange(params) {
  return request.get(`/partner/clinic/withinRange`, { params });
}

export function salesSignin(params) {
  return request.get(`/partner/clinic/salesSignin`, { params });
}

export function getOverview(params) {
  return request.get(`/partner/user/overview/`, { params });
}

export function getSalesBill(params) {
  return request.get(`/partner/bill/sales/list`, { params });
}

export function getSalesBillDetails(params) {
  return request.get(`/partner/bill/sales/details`, { params });
}

export function getClinicScore(id) {
  return request.get(`/partner/clinic/score/${id}`);
}

export function getClinicSales(id) {
  return request.get(`/partner/clinic/getSales/${id}`);
}

export function getDeviceInfo(clinicDeviceRelId) {
  return request.get(`/partner/clinic/device/rel/${clinicDeviceRelId}`);
}

export function getOneClinicServiceOrderList(params) {
  return request.get(`/partner/serviceOrder/getOneClinicServiceOrderList`, { params });
}

export function getOneSalesToDoServiceOrders(params) {
  return request.get(`/partner/serviceOrder/getOneSalesToDoServiceOrders`, { params });
}

export function clinicDevelopingEdit(params) {
  return request.post(`/partner/clinic/developing/edit`, params);
}

export function clinicDevelopingAdd(params) {
  return request.post(`/partner/clinic/developing/add`, params);
}

export function mealListService() {
  return request.get('/partner/meal/listService');
}

export function mealDetail(id) {
  return request.get(`/partner/meal/${id}`);
}

export function calculatePrice(params) {
  return request.post('/partner/serviceOrder/calculatePrice', params);
}

export function wxPay(params) {
  return request.post(`/partner/serviceOrder/wxPay`, params);
}

export function createServiceOrder(params) {
  return request.post('/partner/serviceOrder/createServiceOrder', params);
}

export function orderList(params) {
  return request.get(`/partner/serviceOrder/list`, { params });
}

export function getServiceOrder(serviceOrderId) {
  return request.get(`/partner/serviceOrder/${serviceOrderId}`);
}

export function orderBindClinic(params) {
  return request.post(`/partner/serviceOrder/bindClinic`, params);
}

export function orderBindExport(params) {
  return request.post(`/partner/bill/sales/export?billId=${params.billId}`, {}, { responseType: 'blob' });
}

export function getAddressList(params) {
  return request.get(`/partner/address/list`, { params });
}
export function addAddress(params) {
  return request.post(`/partner/address`, params);
}

export function editAddress(params) {
  return request.put(`/partner/address`, params);
}

export function getAddress(id) {
  return request.get(`/partner/address/${id}`);
}

export function deleteAddress(id) {
  return request.delete(`/partner/address/${id}`);
}

export function createFeedback(params) {
  return request.post(`/partner/feedback`, params);
}

export function getClinicDeviceStock(clinicId) {
  return request.get(`/partner/clinic/stock/getClinicDeviceStock/${clinicId}`);
}

export function getPerformanceList(params) {
  return request.get(`/partner/performance/list`, { params });
}

export function reduceOrderInventory(params) {
  return request.post(`/partner/serviceOrder/reduceOrderInventory`, params);
}

export function stockSalesList(params) {
  return request.get(`/partner/stockSales/list`, { params });
}

export function listDeviceStockSales(params) {
  return request.get(`/partner/stockSales/listDeviceStockSales`, { params });
}

export function transferSalesStock(params) {
  return request.post(`/partner/stockSales/transferSalesStock`, params);
}

export function salesStockRecord(params) {
  return request.get(`/partner/salesStockRecord/list`, { params });
}

export function addDeviceApplyRecord(params) {
  return request.post(`/partner/deviceApplyRecord`, params);
}

export function deviceApplyRecordList(params) {
  return request.get(`/partner/deviceApplyRecord/list`, { params });
}

export function getSalesDevices(params) {
  return request.get(`/partner/clinic/device/rel/getSalesDevices`, { params });
}

export function deviceRecord(params) {
  return request.post(`/partner/deviceRecord`, params);
}

export function performanceListSummary(params) {
  return request.get(`/partner/performance/listSummary`, params);
}

export function contractList(params) {
  return request.get(`/partner/contract/list/clinic`, params);
}

export function contractListClinic(params) {
  return request.get(`/partner/contract/list/clinic/sign`, { params });
}

export function contractApply(params) {
  return request.post(`/partner/contract/apply`, params);
}
