<template>
  <div class="clinic-item" @click="toDetail(info)">
    <div class="clinic-content">
      <div class="clinic-image">
        <van-image fit="cover" :src="info.frontPhoto" :alt="info.clinicName" class="clinic-avatar" lazy-load />
      </div>
      <div class="clinic-info">
        <h3 class="clinic-name">{{ info.clinicName }}</h3>
        <p v-if="active !== 1 && active !== 2" class="clinic-tip">
          <!-- 后续修改 -->
          销量：{{ info.salesQuantity || info.cwSalseAmount || 0 }}
        </p>
        <p v-if="active === 0" class="clinic-tip">上次打卡时间：{{ info.signinTime || '无' }}</p>
        <p v-if="active === 1 && auditStatus.enums" class="clinic-tip">
          {{ auditStatus.getValueByKey(info.auditStatus) }} {{ info.auditMes }}
        </p>
      </div>
      <div class="clinic-status">
        <p class="view-details"><icon name="arrow" /></p>
      </div>
      <div class="clinic-alarm">
        <span class="alarm-tag" v-if="info.contractSignStatus" style="background: #00bdd4;">协</span>

        <span class="alarm-tag" v-if="info.stockAlarmLevel === 1" style="background: #ffb811;">库</span>
        <span class="alarm-tag" v-if="info.stockAlarmLevel === 2" style="background: #fe271b;">库</span>

        <span class="alarm-tag" v-if="info.timeAlarmLevel === 1" style="background: #ffb811;">效</span>
        <span class="alarm-tag" v-if="info.timeAlarmLevel === 2" style="background: #fe271b;">效</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Image as VanImage } from 'vant';
import { auditStatusAll } from '@/enum/index';

export default {
  components: { Icon, VanImage },
  data() {
    return {
      auditStatus: {}
    };
  },
  props: {
    active: {
      type: Number
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  async mounted() {
    const [auditStatus] = await Promise.all([auditStatusAll()]);
    this.auditStatus = auditStatus;
  },
  methods: {
    toDetail(info) {
      if (this.active === 1) {
        this.$router.push({
          name: 'partnerClinicInfoEdit',
          query: {
            id: info.clinicId,
            hisId: info.hisId
          }
        });
      } else {
        this.$router.push({
          name: 'partnerClinicInfo',
          query: {
            id: info.clinicId
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.clinic-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.clinic-content {
  display: flex;
  align-items: center;
}

.clinic-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.clinic-avatar {
  width: 100%;
  height: auto;
}

.clinic-info {
  flex: 1;
}

.clinic-name {
  margin-bottom: $tb;
  font-size: $size4;
  font-weight: 600;
  color: $black1;
  line-height: 22px;
}

.clinic-tip {
  color: $gray2;
  font-size: $size6;
  margin-top: 6px;
  line-height: 17px;
}

.clinic-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.view-details {
  font-size: $size4;
  color: $appcolor;
}

.clinic-alarm {
  position: absolute;
  top: 4px;
  right: 0;
  .alarm-tag {
    font-size: 12px;
    border-radius: 50%;
    color: #fff;
    background: $red1;
    padding: 4px;
    display: inline-block;
    margin-right: 4px;
  }
}
</style>
