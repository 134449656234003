<template>
  <van-form ref="formRef">
    <template v-for="(item, index) in fieldList">
      <template v-if="!item.hide">
        <template v-if="item.type === 'date'">
          <field
            :key="item.key"
            :label="item.label + ':'"
            :placeholder="'请选择' + item.label"
            readonly
            clickable
            :value="item.value"
            @click="item.show = true"
            :rules="item.rules"
            :required="item.required"
          />
          <popup :key="'popup_' + item.key" v-model="item.show" position="bottom">
            <datetime-picker
              v-model="item.popupValue"
              type="date"
              @confirm="date => calendarConfirm(date, item)"
              :title="'请选择' + item.label"
              :max-date="item.maxDate"
              :min-date="item.minDate"
            />
          </popup>
        </template>
        <template v-else-if="item.type === 'select'">
          <field
            :key="item.key"
            :label="item.label + ':'"
            :placeholder="'请选择' + item.label"
            readonly
            clickable
            :value="item.value && item.value[item.valueKey || 'text']"
            @click="item.show = true"
            :rules="item.rules"
            :required="item.required"
          />
          <popup :key="'popup_' + item.key" v-model="item.show" position="bottom">
            <picker
              :columns="item.columns"
              @confirm="(value, index) => pickerConfirm(value, index, item)"
              @cancel="item.show = false"
              :value-key="item.valueKey || 'text'"
            />
          </popup>
        </template>
        <template v-else-if="item.type === 'radio-group'">
          <field :key="item.key" :label="item.label + ':'" :rules="item.rules" :required="item.required">
            <template #input>
              <radio-group v-model="item.value" direction="horizontal">
                <radio
                  v-for="(radioItem, radioIndex) in item.actions"
                  :key="'radio' + radioIndex"
                  :name="radioItem.key"
                >
                  {{ radioItem.name }}
                </radio>
              </radio-group>
            </template>
          </field>
        </template>
        <template v-else-if="item.type === 'area'">
          <field
            :key="item.key"
            :label="item.label + ':'"
            :placeholder="'请选择' + item.label"
            readonly
            clickable
            :value="item.value"
            @click="item.show = true"
            :rules="item.rules"
            :required="item.required"
          />
          <popup :key="'popup_' + item.key" v-model="item.show" position="bottom">
            <Area
              :title="'请选择' + item.label"
              :value="item.popupValue"
              :area-list="areaList"
              @confirm="values => onConfirm(values, item)"
              @cancel="item.show = false"
            />
          </popup>
        </template>
        <template v-else-if="item.type === 'uploader'">
          <field
            :key="item.key"
            name="uploader"
            :label="item.label + ':'"
            :rules="[{ required: item.required, message: '请上传' + item.label }]"
            :required="item.required"
          >
            <template #input>
              <uploader
                :maxCount="item.maxCount || 1"
                v-model="item.value"
                :after-read="from => afterRead(from, item)"
              />
            </template>
          </field>
        </template>
        <template v-else>
          <field
            :key="index"
            v-model="item.value"
            :label="item.label + ':'"
            :type="item.type"
            :maxLength="item.maxLength"
            :placeholder="'请输入' + item.label"
            :rules="item.rules"
            :required="item.required"
          >
            <template #button v-if="item.slotButton">
              <div v-html="item.slotButton"></div>
            </template>
          </field>
        </template>
      </template>
    </template>
  </van-form>
</template>

<script>
import services from '@/service';
import { Form as VanForm, Field, RadioGroup, Radio, Uploader, Popup, Area, DatetimePicker } from 'vant';
import { areaList } from '@vant/area-data';
import Picker from '@/components/picker';

const { JSEncrypt } = require('encryptlong');
import SparkMD5 from 'spark-md5';
const jsRsa = new JSEncrypt();

import { genderType } from '@/enum';
import compressImageFile from '@/lib/compressImageFile';

export default {
  components: { VanForm, Field, RadioGroup, Radio, Uploader, Popup, Area, DatetimePicker, Picker },
  props: {
    fields: {
      type: Array,
      default: () => {
        return [];
      }
    },
    fieldsHandle: {
      type: Function
    }
  },
  data() {
    return {
      uploaderMaxSize: 10 * 1024 * 1024,
      fieldList: []
    };
  },
  watch: {
    fieldList: {
      handler: function(fields) {
        this.fieldsHandle && this.fieldsHandle(fields);
      },
      deep: true
    }
  },
  computed: {
    areaList() {
      return areaList;
    }
  },
  methods: {
    async getPublickey() {
      const res = await services.common.getPublickey();
      jsRsa.setPublicKey(res.data);
    },
    oversize() {
      this.$toast(`文件大小不能超过${this.uploaderMaxSize / 1024 / 1024}MB，请重新选择`);
    },
    async afterRead(form, item) {
      const file = await compressImageFile(form.file);

      const formData = new FormData();
      formData.append('file', file);

      let res;
      // 加密上传
      if (item.isEncrypt) {
        const fileMd5 = await this.getFileMd5(form.file);
        const content = `${fileMd5}#${new Date().getTime()}#${item.encryptText}`;
        const safeCode = jsRsa.encryptLong(content);
        formData.append('safeCode', encodeURIComponent(safeCode));
        res = await services.common.notAuthFileUpload(formData);
      } else {
        res = await services.common.fileUpload(formData);
      }

      if (item.ocrType) {
        this.opentoolOcr(item.ocrType, res.data.url, item.ocrKey);
      }
      item.value = [res.data];
    },
    async opentoolOcr(ocrType, imageUrl, ocrKey) {
      const { data } = await services.common.opentoolOcr({ ocrType, imageUrl });
      for (const i in ocrKey) {
        const item = this.fieldList.find(o => o.key === ocrKey[i]);
        if (data[i] && data[i] !== '无' && item) {
          if (i === 'gender') {
            item.value = genderType.getKeyByValue(data[i]);
          } else {
            item.value = data[i];
          }
        }
      }
    },
    onConfirm(values, item) {
      item.value = values
        .filter(item => !!item)
        .map(item => item.name)
        .join('/');
      item.show = false;
    },
    calendarConfirm(date, item) {
      item.value = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      item.show = false;
    },
    pickerConfirm(value, index, item) {
      item.value = value;
      item.show = false;
      this.fieldChange({ value, index, item });
    },
    fieldChange(e) {
      this.$emit('fieldChange', e);
    },
    getFileMd5(file) {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = function(e) {
          const spark = new SparkMD5.ArrayBuffer();
          spark.append(e.target.result);
          const md5 = spark.end();
          resolve(md5);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    async validate() {
      console.log(this.fieldList);
      await this.$refs.formRef.validate();

      const params = {};
      this.fieldList.forEach(i => {
        if (!i.hide) {
          if (i.type === 'area' && i.value) {
            const areaList = i.value.split('/');
            params.province = areaList[0];
            params.city = areaList[1];
            params.district = areaList[2];
          } else if (i.type === 'uploader' && i.value && i.value.length && i.maxCount === undefined) {
            params[i.key] = i.value[0].url;
          } else {
            params[i.key] = i.value;
          }
        }
      });
      return params;
    }
  },
  mounted() {
    this.fieldList = [...this.fields];
    this.getPublickey();
  }
};
</script>

<style lang="scss" scoped></style>
