import request from '@/lib/request';

let cache = {};
function requestCache({ url, method, params = {} }) {
  const cacheKey = url + JSON.stringify(params);

  if (cache[cacheKey]) {
    return Promise.resolve(cache[cacheKey]);
  }

  const promise = request({
    url,
    method,
    params
  });
  cache[cacheKey] = promise;
  return promise;
}

export function clearCache() {
  cache = {};
}

// 查询产品配置列表
export function settingListAll() {
  return request({
    url: '/cms/setting/listAll',
    method: 'get'
  });
}

// 时间类型
export function selectDateTypeAll() {
  return requestCache({
    url: '/auth/dictionary/selectDateType',
    method: 'get'
  });
}

// 设备类型
export function goodsSubTypeAll() {
  return requestCache({
    url: '/auth/dictionary/goodsSubTypeEnum',
    method: 'get'
  });
}

// 检测订单状态
export function checkOrderStateAll() {
  return requestCache({
    url: '/auth/dictionary/checkOrderStateEnum',
    method: 'get'
  });
}

// 订单状态列表
export function serviceOrderStateAll() {
  return requestCache({
    url: '/auth/dictionary/serviceOrderStateEnum',
    method: 'get'
  });
}

// 诊所列表
export function clinicAll(params) {
  return request({
    url: '/partner/clinic/allClinic',
    method: 'get',
    params
  });
}

// 获取产品列表（业绩详情专用）
export function allGoodsSetting(params) {
  return request({
    url: '/partner/bill/sales/allGoodsSetting',
    method: 'get',
    params
  });
}

// 获取业务员列表（业绩详情专用）
export function allSalesList(params) {
  return request({
    url: '/partner/bill/sales/allSalesList',
    method: 'get',
    params
  });
}

// 诊所审批状态和医生审批状态
export function auditStatusAll() {
  return requestCache({
    url: '/auth/dictionary/auditStatusEnum',
    method: 'get'
  });
}

// 查询所有业务员库存列表
export function stockSalesListAll() {
  return request({
    url: '/partner/stockSales/listAll',
    method: 'get'
  });
}

// 库存记录来源类型
export function stockRecordSrcAll() {
  return request({
    url: '/auth/dictionary/stockRecordSrcEnum',
    method: 'get'
  });
}

// 库存记录类型(操作)
export function stockRecordTypeAll() {
  return request({
    url: '/auth/dictionary/stockRecordTypeEnum',
    method: 'get'
  });
}

// 自身团队业务员
export function teamAllSalesAll() {
  return request({
    url: '/partner/sales/teamAllSales',
    method: 'get'
  });
}

// 耗材列表
export function consumablesAll() {
  return request({
    url: '/partner/setting/listAllConsumables/',
    method: 'get'
  });
}

// 可签约合同列表
export function contractClinicListAll() {
  return request({
    url: '/partner/contract/list/clinic',
    method: 'get'
  });
}
