var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "clinic-item",
      on: {
        click: function ($event) {
          return _vm.toDetail(_vm.info)
        },
      },
    },
    [
      _c("div", { staticClass: "clinic-content" }, [
        _c(
          "div",
          { staticClass: "clinic-image" },
          [
            _c("van-image", {
              staticClass: "clinic-avatar",
              attrs: {
                fit: "cover",
                src: _vm.info.frontPhoto,
                alt: _vm.info.clinicName,
                "lazy-load": "",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "clinic-info" }, [
          _c("h3", { staticClass: "clinic-name" }, [
            _vm._v(_vm._s(_vm.info.clinicName)),
          ]),
          _vm.active !== 1 && _vm.active !== 2
            ? _c("p", { staticClass: "clinic-tip" }, [
                _vm._v(
                  " 销量：" +
                    _vm._s(
                      _vm.info.salesQuantity || _vm.info.cwSalseAmount || 0
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.active === 0
            ? _c("p", { staticClass: "clinic-tip" }, [
                _vm._v("上次打卡时间：" + _vm._s(_vm.info.signinTime || "无")),
              ])
            : _vm._e(),
          _vm.active === 1 && _vm.auditStatus.enums
            ? _c("p", { staticClass: "clinic-tip" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.auditStatus.getValueByKey(_vm.info.auditStatus)
                    ) +
                    " " +
                    _vm._s(_vm.info.auditMes) +
                    " "
                ),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "clinic-status" }, [
          _c(
            "p",
            { staticClass: "view-details" },
            [_c("icon", { attrs: { name: "arrow" } })],
            1
          ),
        ]),
        _c("div", { staticClass: "clinic-alarm" }, [
          _vm.info.contractSignStatus
            ? _c(
                "span",
                {
                  staticClass: "alarm-tag",
                  staticStyle: { background: "#00bdd4" },
                },
                [_vm._v("协")]
              )
            : _vm._e(),
          _vm.info.stockAlarmLevel === 1
            ? _c(
                "span",
                {
                  staticClass: "alarm-tag",
                  staticStyle: { background: "#ffb811" },
                },
                [_vm._v("库")]
              )
            : _vm._e(),
          _vm.info.stockAlarmLevel === 2
            ? _c(
                "span",
                {
                  staticClass: "alarm-tag",
                  staticStyle: { background: "#fe271b" },
                },
                [_vm._v("库")]
              )
            : _vm._e(),
          _vm.info.timeAlarmLevel === 1
            ? _c(
                "span",
                {
                  staticClass: "alarm-tag",
                  staticStyle: { background: "#ffb811" },
                },
                [_vm._v("效")]
              )
            : _vm._e(),
          _vm.info.timeAlarmLevel === 2
            ? _c(
                "span",
                {
                  staticClass: "alarm-tag",
                  staticStyle: { background: "#fe271b" },
                },
                [_vm._v("效")]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }