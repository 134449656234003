import request from '@/lib/request';

// 查询产品配置列表
export function settingListAll() {
  return request({
    url: '/cms/setting/listAll',
    method: 'get'
  });
}

// 检测项目列表
export function goodsSettingListAll() {
  return request({
    url: '/cms/statistics/h5/goodsSetting/list',
    method: 'get'
  });
}

// 公司仓库列表
export function compWarehouseAll() {
  return request({
    url: '/cms/warehouse/listAll',
    method: 'get'
  });
}

// 设备列表
export function settingDevicesAll() {
  return request({
    url: '/cms/setting/listAllDevices/',
    method: 'get'
  });
}

// 耗材列表
export function consumablesAll() {
  return request({
    url: '/cms/setting/listAllConsumables/',
    method: 'get'
  });
}

// 诊所列表
export function clinicListAll() {
  return request({
    url: '/cms/clinic/nopage/list',
    method: 'get'
  });
}

// 所有业务员和合伙人
export function salesAll() {
  return request({
    url: '/cms/sales/nopage/list',
    method: 'get'
  });
}

// 所有业务员
export function salesListAll() {
  return request({
    url: '/cms/sales/nopage/list',
    method: 'get',
    params: { salesType: 0 }
  });
}

// 所有合伙人
export function partnerListAll() {
  return request({
    url: '/cms/sales/nopage/list',
    method: 'get',
    params: { salesType: 1 }
  });
}

// B级合伙人
export function partnerListB() {
  return request({
    url: '/cms/sales/nopage/list',
    method: 'get',
    params: { salesType: 1, levelId: 2 }
  });
}

// C级合伙人
export function partnerListC() {
  return request({
    url: '/cms/sales/nopage/list',
    method: 'get',
    params: { salesType: 1, levelId: 3 }
  });
}

// 物流列表
export function logisticsCompanyListAll() {
  return request({
    url: '/auth/dictionary/logisticsCompanyList',
    method: 'get'
  });
}

// 订单状态列表
export function serviceOrderStateAll() {
  return request({
    url: '/auth/dictionary/serviceOrderStateEnum',
    method: 'get'
  });
}

// 库存记录来源类型
export function stockRecordSrcAll() {
  return request({
    url: '/auth/dictionary/stockRecordSrcEnum',
    method: 'get'
  });
}

// 库存记录类型(操作)
export function stockRecordTypeAll() {
  return request({
    url: '/auth/dictionary/stockRecordTypeEnum',
    method: 'get'
  });
}

// 分类
export function goodsSubTypeAll() {
  return request({
    url: '/auth/dictionary/goodsSubTypeEnum',
    method: 'get'
  });
}

// 停用或启用状态
export function enableOrDisable() {
  return request({
    url: '/auth/dictionary/enableOrDisable',
    method: 'get'
  });
}

// 诊所审批状态和医生审批状态
export function auditStatusAll() {
  return request({
    url: '/auth/dictionary/auditStatusEnum',
    method: 'get'
  });
}

// 校验模式
export function testModeAll() {
  return request({
    url: '/auth/dictionary/testModeEnum',
    method: 'get'
  });
}

// 合伙人级别列表
export function partnerLevelListAll() {
  return request({
    url: '/cms/partnerLevel/list',
    method: 'get'
  });
}

// 性别
export function genderListAll() {
  return request({
    url: '/auth/dictionary/genderEnums',
    method: 'get'
  });
}

// 检测订单状态
export function checkOrderStateAll() {
  return request({
    url: '/auth/dictionary/checkOrderStateEnum',
    method: 'get'
  });
}

// 厂家
export function thirdCompanyAll() {
  return request({
    url: '/cms/thirdCompany/noPageList',
    method: 'get'
  });
}

// 用户角色类型
export function roleTypeAll() {
  return request({
    url: '/auth/dictionary/roleType',
    method: 'get'
  });
}

// 站内信的消息类型
export function messageTypeAll() {
  return request({
    url: '/auth/dictionary/messageType',
    method: 'get'
  });
}

// 站内信的消息分类
export function messageCateGoryAll() {
  return request({
    url: '/auth/dictionary/messageCateGory',
    method: 'get'
  });
}

// 意见反馈类型枚举
export function feedbackTypeAll() {
  return request({
    url: '/auth/dictionary/feedbackType',
    method: 'get'
  });
}

// 短信模板CODE
export function smsTemplateEnumAll() {
  return request({
    url: '/auth/dictionary/smsTemplateEnum',
    method: 'get'
  });
}

// 成功失败状态枚举
export function successFailedStatusEnumAll() {
  return request({
    url: '/auth/dictionary/successFailedStatus',
    method: 'get'
  });
}

// 是否异步标识枚举
export function syncFlagStatusEnumAll() {
  return request({
    url: '/auth/dictionary/syncFlagStatus',
    method: 'get'
  });
}

// 套餐
export function mealTwoAll() {
  return request({
    url: '/cms/meal/list?pageNum=1&pageSize=1000&paymentMode=2',
    method: 'get'
  });
}

// 套餐
export function mealAnyAll() {
  return request({
    url: '/cms/meal/list?pageNum=1&pageSize=1000',
    method: 'get'
  });
}
