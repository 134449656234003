var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "address-edit" }, [
        _c(
          "div",
          { staticClass: "address-edit-form" },
          [
            _c("field", {
              attrs: {
                name: "姓名",
                label: "姓名",
                placeholder: "请输入姓名",
                rules: [{ required: true, message: "请输入姓名" }],
              },
              model: {
                value: _vm.formInfo.addressee,
                callback: function ($$v) {
                  _vm.$set(_vm.formInfo, "addressee", $$v)
                },
                expression: "formInfo.addressee",
              },
            }),
            _c("field", {
              attrs: {
                name: "电话",
                label: "电话",
                placeholder: "请输入电话",
                type: "number",
                maxLength: "11",
                rules: [{ required: true, message: "请输入电话" }],
              },
              model: {
                value: _vm.formInfo.phone,
                callback: function ($$v) {
                  _vm.$set(_vm.formInfo, "phone", $$v)
                },
                expression: "formInfo.phone",
              },
            }),
            _c("field", {
              attrs: {
                name: "地区",
                label: "地区",
                placeholder: "请选择地区",
                readonly: "",
                clickable: "",
                value: _vm.area,
                rules: [{ required: true, message: "请选择地区" }],
              },
              on: {
                click: function ($event) {
                  _vm.showArea = true
                },
              },
            }),
            _c(
              "popup",
              {
                attrs: { position: "bottom" },
                model: {
                  value: _vm.showArea,
                  callback: function ($$v) {
                    _vm.showArea = $$v
                  },
                  expression: "showArea",
                },
              },
              [
                _c("Area", {
                  attrs: { value: _vm.areaValue, "area-list": _vm.areaList },
                  on: {
                    confirm: _vm.onConfirm,
                    cancel: function ($event) {
                      _vm.showArea = false
                    },
                  },
                }),
              ],
              1
            ),
            _c("field", {
              attrs: {
                name: "详细地址",
                label: "详细地址",
                placeholder: "请输入详细地址",
                type: "textarea",
                maxLength: "50",
                rules: [{ required: true, message: "请输入详细地址" }],
              },
              model: {
                value: _vm.formInfo.address,
                callback: function ($$v) {
                  _vm.$set(_vm.formInfo, "address", $$v)
                },
                expression: "formInfo.address",
              },
            }),
            _c("field", {
              attrs: { name: "switch", label: "是否默认" },
              scopedSlots: _vm._u([
                {
                  key: "input",
                  fn: function () {
                    return [
                      _c("van-switch", {
                        attrs: { size: "20" },
                        model: {
                          value: _vm.formInfo.defFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInfo, "defFlag", $$v)
                          },
                          expression: "formInfo.defFlag",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c("calendar", {
              on: { confirm: _vm.calendarConfirm },
              model: {
                value: _vm.showCalendar,
                callback: function ($$v) {
                  _vm.showCalendar = $$v
                },
                expression: "showCalendar",
              },
            }),
          ],
          1
        ),
      ]),
      _vm.addBtn && !this.isEdit
        ? _c("div", { staticClass: "add-btn", on: { click: _vm.add } }, [
            _c("span", [_vm._v("导入诊所地址")]),
          ])
        : _vm._e(),
      _c("fixedButton", {
        attrs: { text: "保存" },
        on: { emitEvent: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }