<template>
  <div class="patient-login">
    <div class="login-bg"></div>
    <div class="header">
      <p class="header-title">爱检医</p>
      <p class="header-login">{{ showType }}</p>
    </div>
    <div class="type-warp" v-if="loginType === 'sms'">
      <!-- <span class="back-btn" @click="loginType = ''"><Icon name="arrow-left" />返回</span> -->
      <div class="input-warp">
        <input type="number" maxlength="11" v-model="fromInfo.mobile" placeholder="请输入手机号" />
        <input type="number" maxlength="6" v-model="fromInfo.smsCode" placeholder="请输入验证码" />
        <span class="sms-tip" @click="getCode"> {{ countdown > 0 ? `${countdown} 秒后重新获取` : '获取验证码' }} </span>
      </div>
      <div class="login-btn" @click="smsLogin">登 录</div>
      <div class="login-other"><span class="withe-btn" @click="loginType = 'password'">密码登录</span></div>
    </div>
    <div class="type-warp" v-if="loginType === 'password'">
      <!-- <span class="back-btn" @click="loginType = ''"><Icon name="arrow-left" />返回</span> -->
      <div class="input-warp">
        <input type="number" maxlength="11" v-model="fromInfo.mobile" placeholder="请输入手机号" />
        <input type="password" maxlength="20" v-model="fromInfo.password" placeholder="请输入密码" />
      </div>
      <div class="remember"><checkbox v-model="rememberPassword">记住密码</checkbox></div>
      <div class="login-btn" @click="passwordLogin">登 录</div>
      <div class="login-other"><span class="withe-btn" @click="loginType = 'sms'">验证码登录</span></div>
    </div>
    <div class="type-warp" v-if="!loginType">
      <div class="login-btn" @click="loginType = 'sms'">验证码登录</div>
      <div class="login-btn" @click="loginType = 'password'">密码登录</div>
    </div>
    <!-- <div class="wx-btn" @click="wxLogin">微信一键登录</div> -->
    <div class="login-tip" v-if="false">
      <checkbox v-model="isAgree">已阅读并同意《用户协议》和《隐私政策》</checkbox>
    </div>
  </div>
</template>

<script>
import services from '@/service';
import { validatePhoneNumber } from '@/lib/utils';
import { Checkbox } from 'vant';
import qs from 'qs';
import { getUserType } from '@/lib/utils';
import { isDev } from '@/global';
import { userTypeAll } from '@/enum/index';
import { storageEncrypt, storageDecrypt } from '@/lib/jsencrypt';

export default {
  components: {
    Checkbox
  },
  data() {
    return {
      loginType: 'sms',
      rememberPassword: true,
      isAgree: true,
      fromInfo: {
        mobile: '',
        smsCode: '',
        password: '',
        userType: userTypeAll.getKeyByValue('诊所端')
      },
      countdown: 0
    };
  },
  computed: {
    userType() {
      return getUserType();
    },
    showType() {
      const type = {
        sms: '验证码登录',
        password: '密码登录'
      };
      return type[this.loginType];
    }
  },
  methods: {
    getCode() {
      if (!validatePhoneNumber(this.fromInfo.mobile)) {
        this.$toast('请输入正确的手机号');
        return;
      }
      if (this.countdown > 0) {
        return;
      }
      this.sendVerificationCode();
    },
    async sendVerificationCode() {
      try {
        await services[this.userType].sendSms(this.fromInfo.mobile);
        this.countdown = 60;
        const timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
      } catch (error) {
        this.$toast(error.msg || '获取验证码失败');
      }
    },
    async smsLogin() {
      if (!validatePhoneNumber(this.fromInfo.mobile)) return this.$toast('请输入正确的手机号');
      if (this.fromInfo.smsCode.toString().length !== 6) return this.$toast('请输入完整验证码');
      await this.checkIsAgree();
      const res = await services[this.userType].smsLogin(this.fromInfo);
      this.dataInit(res.data.access_token);
    },
    async passwordLogin() {
      if (!validatePhoneNumber(this.fromInfo.mobile)) return this.$toast('请输入正确的手机号');
      if (this.fromInfo.password.length < 6) return this.$toast('请输入正确的密码');
      await this.checkIsAgree();

      const res = await services.common.passwordlogin(this.fromInfo);

      if (this.rememberPassword) {
        storageEncrypt(`${this.userType}_login_mobile`, this.fromInfo.mobile);
        storageEncrypt(`${this.userType}_login_password`, this.fromInfo.password);
      } else {
        storageEncrypt(`${this.userType}_login_password`, '');
      }

      this.dataInit(res.data.access_token);
    },
    async dataInit(token) {
      try {
        await Promise.all([
          this.$store.dispatch('set_token', token),
          this.$store.dispatch(`set_${this.userType}Info`),
          this.$store.dispatch('set_checkOrderState')
        ]);

        const { redirect_name: redirectName = '', redirect_query: redirectQuery = '' } = this.$route.query;

        this.$router.replace({
          path: `/${this.userType}-home`
        });
        if (redirectName) {
          const query = qs.parse(decodeURIComponent(redirectQuery));
          setTimeout(() => {
            this.$router.push({ name: redirectName, query });
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        // this.$toast(error.msg);
      }
    },
    async checkIsAgree() {
      return new Promise((resolve, reject) => {
        if (this.isAgree) return resolve();
        this.$dialog
          .confirm({
            title: '提示',
            message: '请阅读并同意《用户协议》和《隐私政策》'
          })
          .then(() => {
            this.isAgree = true;
            resolve();
          })
          .catch(() => {
            reject({ msg: '请阅读并同意《用户协议》和《隐私政策》' });
          });
      });
    },
    async wxLogin() {
      await this.checkIsAgree();
      this.$router.push({
        path: `/${this.userType}-home`
      });
    }
  },
  mounted() {
    if (isDev) {
      this.fromInfo.mobile = '15813306682';
      this.fromInfo.smsCode = '111222';
    }
    this.fromInfo.mobile = storageDecrypt(`${this.userType}_login_mobile`) || '';
    this.fromInfo.password = storageDecrypt(`${this.userType}_login_password`) || '';
  }
};
</script>

<style lang="scss" scoped>
.patient-login {
  height: 100%;
  padding: $lr $tb;
  background: #fff;
  position: relative;
  .header {
    margin-top: 60px;
    z-index: 20;
    position: relative;
    color: #fff;
    .header-login {
      font-size: 20px;
    }
    p {
      font-size: 40px;
      font-weight: 400;
      margin-bottom: 24px;
    }
  }

  .type-warp {
    position: relative;
    margin-top: 110px;
    .back-btn {
      padding: 6px;
      display: inline-block;
      border: 1px solid $gray2;
      color: $gray2;
      border-radius: $radius;
      margin-bottom: $tb;
      font-size: $size6;
    }
  }
  .input-warp {
    position: relative;
    font-size: $size3;
    color: $black1;
    input {
      display: block;
      width: 100%;
      height: 44px;
      border-radius: $radius;
      background: lighten($appcolor, 55%);
      margin-bottom: $edge;
      padding: 0 $lr;
      box-sizing: border-box;
      // border-bottom: 1px solid $gray3;
      box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
    }
    .sms-tip {
      position: absolute;
      right: 12px;
      bottom: 16px;
      text-align: right;
      font-size: $size6;
    }
  }

  .remember {
    display: flex;
    flex-direction: row-reverse;
    margin: $tb 0;
  }

  .login-btn {
    @include flexCenter();
    width: 100%;
    height: $btnheight;
    border-radius: $radius;
    background: $btnbg;
    color: #fff;
    margin-bottom: $edge;
    // margin-top: 50px;
  }
  .login-other {
    text-align: center;
    margin-top: 30px;
    .withe-btn {
      background: #fff;
      color: $appcolor;
      border-bottom: 1px solid $appcolor;
      cursor: pointer;
    }
  }

  .wx-btn {
    @include flexCenter();
    width: 100%;
    height: $btnheight;
    border-radius: $radius;
    background: #24d270;
    color: #fff;
  }
  .login-tip {
    position: absolute;
    bottom: 0;
    // width: 100%;
    z-index: 9999; /* 适当的 z-index 值 */
    width: 95%;
    display: flex;
    justify-content: center;
    bottom: calc(20px + #{env(safe-area-inset-bottom)});
    color: $gray2;
    font-size: $size4;
    text-align: center;
  }
}
</style>
