<template>
  <div>
    <searchFilter :searchForm="searchForm" @emitSearch="emitSearch" immediately v-if="initEnum" />

    <pull-refresh v-model="pullLoading" @refresh="refreshList">
      <list v-model="loading" :finished="!hasNext" finished-text="没有更多了" @load="loadList" :immediate-check="false">
        <div style="text-align: center; padding: 10vh 0" v-if="listInfo.length == 0 && !loading"></div>
        <div class="panel-list">
          <div class="panel-item" v-for="(info, index) in listInfo" :key="index">
            <div class="report-box">
              <div class="report-info report-header">
                <div>
                  <span class="clinic-name">
                    {{ info.patientName }}
                  </span>
                </div>
                <span class="doc-name">{{ info.updateTime }}</span>
              </div>
              <div class="report-info report-project" @click="toDetail(info)">
                <span>
                  {{ info.goodsName }}
                </span>
                <span class="report-read">
                  <!-- {{ checkOrderState.getValueByKey(info.status) }} -->
                  <icon name="arrow" />
                </span>
              </div>
              <!-- <div class="report-code">{{ info.deviceNum }}</div> -->
              <div class="report-foot">
                <div class="foot-line">
                  <span class="patient-name">医生：{{ info.createBy }}</span>
                  <!-- <span class="clinic-name">{{ info.deviceNum }}</span> -->
                  <span class="delete-btn" @click="deleteCheckOrder(info)">
                    取消检测
                  </span>
                </div>
                <div class="foot-line">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </list>
    </pull-refresh>

    <fieldPrompt
      title="审核"
      :show.sync="fieldPromptModel.show"
      :fieldForm="fieldPromptModel.fieldForm"
      :info="fieldPromptModel.info"
      ref="fieldPrompt"
    >
      <div slot="content" class="prompt-content" v-if="fieldPromptModel.info">
        <p>
          设备申请数量：<span style="margin-right: 30px">{{ fieldPromptModel.info.deviceApplyCount }}</span>
        </p>
        <p>
          耗材申请数量：<span style="margin-right: 30px">{{ fieldPromptModel.info.consumablesApplyCount }}</span>
        </p>
      </div>
      <fixedBottom slot="button">
        <div class="btn-warp">
          <div class="btn btn2" @click="changeAudit('1')">不通过</div>
          <div class="btn" @click="changeAudit('2')">通过</div>
        </div>
      </fixedBottom>
    </fieldPrompt>

    <van-dialog
      v-if="fieldPromptModel.info"
      v-model="auditShow"
      title="不通过原因"
      show-cancel-button
      @confirm="sumbitAudit('1')"
    >
      <field v-model="fieldPromptModel.info.remark" label="" type="textarea" placeholder="请输入不通过原因" />
    </van-dialog>

    <Icon name="add" class="add-icon" @click="toAdd" />
  </div>
</template>

<script>
import services from '@/service';
import { Icon, PullRefresh, List, ImagePreview, Field } from 'vant';
import { mapGetters } from 'vuex';
import { getUserType } from '@/lib/utils';
import { deviceApplyAll } from '@/enum/index';
import fieldPrompt from '@/components/fieldPrompt';
import fixedBottom from '@/components/fixedBottom';
import searchFilter from '@/components/searchFilter';
// import { intersection } from 'lodash';

export default {
  components: { Icon, PullRefresh, List, fieldPrompt, fixedBottom, Field, searchFilter },
  data() {
    return {
      fieldPromptModel: {
        show: false,
        fieldForm: {}
      },
      auditShow: false,
      orders: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      },
      searchParams: {},
      listInfo: [],
      deviceApplyAll,
      statusDefaultValue: '',
      clinicList: {}
    };
  },
  computed: {
    ...mapGetters(['clinicId', 'adminInfo']),
    searchForm() {
      return {
        clinicName: {
          label: '诊所',
          component: 'input'
        },
        status: {
          label: '申请状态',
          component: 'select',
          options: this.deviceApplyAll.enums,
          defaultValue: this.statusDefaultValue
        },
        salesName: {
          label: '申请人',
          component: 'input'
        }
      };
    },
    initEnum() {
      return this.deviceApplyAll.enums;
    }
  },
  created() {
    if (['admin'].includes(getUserType()) && this.adminInfo.roles.includes('cgy')) {
      this.statusDefaultValue = '2';
    }
  },
  async mounted() {
    // const [clinicList] = await Promise.all([clinicAll()]);
    // this.clinicList = clinicList;
    // this.refreshList();
  },
  methods: {
    emitSearch(params) {
      this.searchParams = params;
      this.refreshList();
    },
    showAudit(item) {
      return ['admin'].includes(getUserType()) && ['0'].includes(item.status);
    },
    showShipment(item) {
      return ['admin'].includes(getUserType()) && ['2'].includes(item.status);
    },
    preview(url) {
      ImagePreview([url]);
    },
    showArea(item) {
      return `${item.receiveName} ${item.receiveMobile} ${item.addressProvinceName} ${item.addressCityName} ${item.addressRegionName} ${item.addressDetail}`;
    },
    copyValue(row) {
      return `收货人：${row.receiveName}\n电话：${row.receiveMobile}\n收货地址：${row.addressProvinceName} ${row.addressCityName} ${row.addressRegionName} ${row.addressDetail}`;
    },
    async changeAudit(auditStatus) {
      if (auditStatus === '1') {
        this.auditShow = true;
      } else {
        this.sumbitAudit(auditStatus);
      }
    },
    async audit(item) {
      this.fieldPromptModel.info = item;
      this.fieldPromptModel.show = true;
      this.fieldPromptModel.fieldForm = {
        deviceOutCount: {
          label: '设备实发数量',
          defaultValue: item.deviceApplyCount
        },
        consumablesOutCount: {
          label: '耗材实发数量',
          defaultValue: item.consumablesApplyCount
        }
      };
    },
    async sumbitAudit(auditStatus) {
      const res = this.$refs.fieldPrompt.getSubmit();
      await services[getUserType()].approveDevice({
        deviceApplyRecordId: res.deviceApplyRecordId,
        goodsSettingId: res.goodsSettingId,
        goodsName: res.goodsName,
        deviceOutCount: res.deviceOutCount,
        consumablesOutCount: res.consumablesOutCount,
        status: auditStatus,
        remark: this.fieldPromptModel.info.remark
      });
      this.$toast('操作成功');
      this.fieldPromptModel.show = false;
      this.refreshList();
    },
    fieldPromptEmit() {},

    shipment(item) {
      this.$router.push({
        name: `${getUserType()}DeviceShipment`,
        query: {
          id: item.deviceApplyRecordId
        }
      });
    },
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      // , ...this.searchParams
      const res = await services[getUserType()].contractListClinic({ ...this.queryParams });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
      // this.$store.dispatch('subLoading');
    },
    toAdd() {
      this.$router.push({
        name: 'partnerAddContract'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-item {
  // margin: $tb $lr;
  // border-radius: $radius;
  overflow: hidden;
  position: relative;
}

.report-box {
  background: #fff;
  margin: $tb $lr;
  border-radius: $radius;
  box-shadow: $box-shadow1;
  font-size: $size2;
  line-height: 26px;
  color: $black1;
  // height: 300px;

  .report-header {
    font-size: $size6;
    margin-bottom: $tb;
    .clinic-name {
      background: $btnbg;
      color: #fff;
      padding: 4px $lr;
      border-radius: $radius 0 $radius 0;
    }
    .doc-name {
      color: $gray2;
      padding: 0 $lr;
      position: relative;
      top: 2px;
    }
  }
  .report-name {
    font-size: $size1;
    font-weight: 600;
    padding: $tb $lr;
    color: $gray2;
  }
  .report-code {
    font-size: $size6;
    padding: 0 $lr;
    color: $gray2;
    text-align: right;
  }
  .report-info {
    @include flexCenter(row);
    justify-content: space-between;

    .report-time {
      .clinic-name {
        flex: 1;
        @include hiddenText();
        font-size: $size6;
      }
    }
  }

  .report-project {
    padding: 0 $lr $tb;

    .report-read {
      font-size: $size4;
      color: $appcolor;
      font-weight: 500;
    }
  }

  .report-foot {
    border-top: 1px solid $bordercolor;
    font-size: $size6;
    color: $gray2;
    padding: 4px $lr;

    .foot-line {
      @include flex(row);
      align-items: center;
      justify-content: space-between;
    }

    .delete-btn {
      border: 1px solid $red1;
      color: $red1;
      margin: 4px 0;
      border-radius: 4px;
      padding: 0 10px;
    }
  }
}

.add-icon {
  position: absolute;
  right: 20px;
  bottom: 60px;
  font-size: 50px;
  color: $appcolor;
  box-shadow: $box-shadow1;
  border-radius: 50%;
}
</style>
