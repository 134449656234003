<template>
  <div>
    <div class="address-item" @click="selectAddr">
      <div class="address-content">
        <i class="iconfont icon-shdz" />
        <div class="address-info" v-if="addressInfo.addressId">
          <h3 class="address-name">
            <span>{{ addressInfo.addressee }}</span>
            <span class="address-phone">{{ addressInfo.phone }}</span>
          </h3>
          <p class="address-area">{{ showArea(addressInfo) }}</p>
        </div>
        <div class="address-info" v-else>
          <h3 class="address-area">请选择地址</h3>
        </div>
        <div class="address-status">
          <p class="view-details"><icon name="arrow" /></p>
        </div>
      </div>
    </div>
    <div class="field-page">
      <div class="field-page-form">
        <fieldForm ref="formRef" :fields="fields" v-if="consumables.enums" />
      </div>
      <div style="height: 200px;"></div>
    </div>
    <div style="height: 100px" />
    <fixedButton text="提交" @emitEvent="submit" />
  </div>
</template>

<script>
import { Icon } from 'vant';
import services from '@/service';
import fixedButton from '@/components/fixedButton';
import { mapGetters } from 'vuex';
import fieldForm from '@/components/fieldForm';

import { consumablesAll } from '@/enum/index';

const arr = Array.from({ length: 31 }, (_, i) => ({ text: `${i} 台`, value: `${i}` }));

const arrDefault = [100, 40, 0, 10, 20, 30].map(item => ({
  text: `${item} 份`,
  value: `${item}`
}));
// const arr2 = Array.from({ length: 10 }, (_, i) => ({ text: `${i * 10} 份`, value: `${i * 10}` }));
const arr3 = Array.from({ length: 29 }, (_, i) => ({ text: `${(i + 2) * 100} 份`, value: `${(i + 2) * 100}` }));

export default {
  components: { Icon, fixedButton, fieldForm },
  data() {
    return {
      consumables: {}
    };
  },
  computed: {
    ...mapGetters(['clinicId', 'addressInfo']),
    fields() {
      return [
        {
          label: '耗材',
          key: 'goodsSetting',
          type: 'select',
          rules: [{ required: true, message: '请选择产品' }],
          required: true,
          columns: this.consumables.enums,
          show: false,
          valueKey: 'goodsName',
          value: ''
        },
        {
          label: '设备申请数量',
          key: 'deviceApplyCount',
          type: 'select',
          maxLength: '10',
          rules: [{ required: false, message: '请选择设备申请数量' }],
          required: false,
          columns: arr,
          show: false,
          valueKey: 'text',
          value: ''
        },
        {
          label: '耗材申请数量',
          key: 'consumablesApplyCount',
          type: 'select',
          maxLength: '10',
          rules: [{ required: false, message: '请选择耗材申请数量' }],
          required: false,
          columns: [...arrDefault, ...arr3],
          show: false,
          valueKey: 'text',
          value: ''
        },
        // <template #input>
        //       <radio-group v-model="formInfo.gender" direction="horizontal">
        //         <radio name="0">男</radio>
        //         <radio name="1">女</radio>
        //       </radio-group>
        //     </template>
        {
          label: '申请理由',
          key: 'applyContent',
          type: 'textarea',
          maxLength: '500',
          rules: [{ required: true, message: '请输入申请理由' }],
          required: true,
          value: ''
        }
      ];
    }
  },
  async mounted() {
    const [consumables] = await Promise.all([consumablesAll()]);
    this.consumables = consumables;

    this.fields.find(i => i.key === 'goodsSetting').value = this.consumables.enums[0];
    this.fields.find(i => i.key === 'deviceApplyCount').value = arr[0];
    this.fields.find(i => i.key === 'consumablesApplyCount').value = arrDefault[0];

    // this.getDeviceList();
  },
  methods: {
    async getDeviceList() {
      const res = await services.partner.getDeviceList();
      this.deviceList = res.rows.map(i => {
        return { ...i, text: i.goodsName };
      });

      this.fields.find(i => i.key === 'goodsSetting').value = this.deviceList[0];
    },
    showArea(item) {
      return `${item.province} ${item.city} ${item.district} ${item.address}`;
    },
    selectAddr() {
      this.$router.push({
        name: 'partnerMyAddress',
        query: {
          isSelect: true
        }
      });
    },
    async submit() {
      if (!this.addressInfo.addressId) {
        return this.$toast('请选择收货地址');
      }

      const res = await this.$refs.formRef.validate();

      if (res.consumablesApplyCount.value === '0' && res.deviceApplyCount.value === '0') {
        return this.$toast('请输入申请数量');
      }

      const params = {
        consumablesApplyCount: res.consumablesApplyCount.value,
        deviceApplyCount: res.deviceApplyCount.value,
        applyContent: res.applyContent,
        goodsSettingId: res.goodsSetting.goodsSettingId,
        goodsName: res.goodsSetting.goodsName,
        receiveName: this.addressInfo.addressee,
        receiveMobile: this.addressInfo.phone,
        addressProvinceName: this.addressInfo.province,
        addressCityName: this.addressInfo.city,
        addressRegionName: this.addressInfo.district,
        addressDetail: this.addressInfo.address
      };
      delete params.goodsSetting;

      await services.partner.addDeviceApplyRecord(params);
      this.$toast('提交成功');
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.address-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
  &:before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background: repeating-linear-gradient(
      -45deg,
      #ff6c6c,
      #ff6c6c 20%,
      transparent 0,
      transparent 25%,
      #1989fa 0,
      #1989fa 45%,
      transparent 0,
      transparent 50%
    );
    background-size: 60px;
    content: '';
  }
}

.address-content {
  display: flex;
  align-items: center;

  .icon-shdz {
    font-size: 32px;
    margin-right: $lr;
    color: transparent; /* 将iconfont的颜色改为透明 */
    -webkit-background-clip: text; /* 将背景图的绘画仅作用在文字上 */
    background-image: $btnbg;
  }
}

.address-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.address-avatar {
  width: 100%;
  height: auto;
}

.address-info {
  flex: 1;
}

.address-name {
  margin-bottom: $tb;
  font-size: $size2;
  font-weight: 500;
  color: $black1;
  .address-phone {
    font-size: $size4;
    margin: 0 $lr;
  }
}

.address-area {
  color: $gray2;
  font-size: $size4;
  line-height: 20px;
}

.field-page {
  padding: $tb $lr;
  .field-page-form {
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
  }
}
</style>
