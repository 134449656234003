<template>
  <div>
    <div class="field-page">
      <div class="field-page-form">
        <fieldForm ref="formRef" :fields="fields" v-if="enumInit" @fieldChange="fieldChange" />
      </div>
      <div style="height: 200px;"></div>
    </div>
    <div style="height: 100px" />
    <fixedButton text="提交" @emitEvent="submit" />
  </div>
</template>

<script>
import services from '@/service';
import fixedButton from '@/components/fixedButton';
import { mapGetters } from 'vuex';
import fieldForm from '@/components/fieldForm';

import { contractClinicListAll, clinicAll2 } from '@/enum/index';

export default {
  components: { fixedButton, fieldForm },
  data() {
    return {
      contractClinicList: {},
      clinicList: {},
      contractName: '',
      clinicName: ''
    };
  },
  computed: {
    ...mapGetters(['clinicId', 'addressInfo']),
    fields() {
      return [
        {
          label: '合同',
          key: 'contractId',
          type: 'select',
          rules: [{ required: true, message: '请选择合同' }],
          required: true,
          columns: this.contractClinicList.enums,
          show: false,
          valueKey: 'contractName',
          value: ''
        },
        {
          label: '诊所',
          key: 'clinicId',
          type: 'select',
          rules: [{ required: true, message: '请选择诊所' }],
          required: true,
          columns: this.clinicList.enums,
          show: false,
          valueKey: 'clinicName',
          value: ''
        },
        {
          label: '省份',
          key: 'province',
          type: 'text',
          maxLength: '100',
          rules: [{ required: true, message: '请输入' }],
          required: true,
          value: ''
        },
        {
          label: '市',
          key: 'city',
          type: 'text',
          maxLength: '100',
          rules: [{ required: true, message: '请输入' }],
          required: true,
          value: ''
        },
        {
          label: '区',
          key: 'district',
          type: 'text',
          maxLength: '100',
          rules: [{ required: true, message: '请输入' }],
          required: true,
          value: ''
        },
        {
          label: '详细地址',
          key: 'address',
          type: 'text',
          maxLength: '100',
          rules: [{ required: true, message: '请输入' }],
          required: true,
          value: ''
        },
        {
          label: '法人名称',
          key: 'corporateName',
          type: 'text',
          maxLength: '100',
          rules: [{ required: true, message: '请输入' }],
          required: true,
          value: ''
        },
        {
          label: '法人身份证',
          key: 'corporateIdCard',
          type: 'text',
          maxLength: '100',
          rules: [{ required: true, message: '请输入' }],
          required: true,
          value: ''
        },
        {
          label: '手机号码	',
          key: 'phone',
          type: 'text',
          maxLength: '11',
          rules: [{ required: true, message: '请输入' }],
          required: true,
          value: ''
        }
      ];
    },
    enumInit() {
      return this.contractClinicList.enums && this.clinicList.enums;
    }
  },
  watch: {
    option: {
      handler: function(val) {
        this.getClinicInfo(val);
      },
      deep: true
    }
  },

  async mounted() {
    const [contractClinicList, clinicList] = await Promise.all([contractClinicListAll(), clinicAll2()]);
    this.contractClinicList = contractClinicList;
    this.clinicList = clinicList;
  },
  methods: {
    async getDeviceList() {
      const res = await services.partner.getDeviceList();
      this.deviceList = res.rows.map(i => {
        return { ...i, text: i.goodsName };
      });

      this.fields.find(i => i.key === 'goodsSetting').value = this.deviceList[0];
    },
    showArea(item) {
      return `${item.province} ${item.city} ${item.district} ${item.address}`;
    },
    selectAddr() {
      this.$router.push({
        name: 'partnerMyAddress',
        query: {
          isSelect: true
        }
      });
    },
    async submit() {
      const res = await this.$refs.formRef.validate();

      const params = {
        ...res
      };
      params.contractId = res.contractId.contractId;
      params.contractName = this.contractName;
      params.clinicId = res.clinicId.clinicId;
      params.clinicName = this.clinicName;

      console.log(params);
      await services.partner.contractApply(params);
      this.$toast('提交成功');
      this.$router.go(-1);
    },
    fieldChange(e) {
      if (e.item.key === 'clinicId') {
        this.getClinicInfo(e.value.clinicId);
        this.clinicName = e.value.clinicName;
      } else if (e.item.key === 'contractId') {
        this.contractName = e.value.contractName;
      }
    },
    async getClinicInfo(clinicId) {
      const { data } = await services.partner.getClinicInfo(clinicId);

      ['address', 'city', 'corporateIdCard', 'corporateName', 'district', 'phone', 'province'].forEach(e => {
        this.fields.find(i => i.key === e).value = data[e];
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.address-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
  &:before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background: repeating-linear-gradient(
      -45deg,
      #ff6c6c,
      #ff6c6c 20%,
      transparent 0,
      transparent 25%,
      #1989fa 0,
      #1989fa 45%,
      transparent 0,
      transparent 50%
    );
    background-size: 60px;
    content: '';
  }
}

.address-content {
  display: flex;
  align-items: center;

  .icon-shdz {
    font-size: 32px;
    margin-right: $lr;
    color: transparent; /* 将iconfont的颜色改为透明 */
    -webkit-background-clip: text; /* 将背景图的绘画仅作用在文字上 */
    background-image: $btnbg;
  }
}

.address-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.address-avatar {
  width: 100%;
  height: auto;
}

.address-info {
  flex: 1;
}

.address-name {
  margin-bottom: $tb;
  font-size: $size2;
  font-weight: 500;
  color: $black1;
  .address-phone {
    font-size: $size4;
    margin: 0 $lr;
  }
}

.address-area {
  color: $gray2;
  font-size: $size4;
  line-height: 20px;
}

.field-page {
  padding: $tb $lr;
  .field-page-form {
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
  }
}
</style>
