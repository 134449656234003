var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "field-page" }, [
        _c(
          "div",
          { staticClass: "field-page-form" },
          [
            _vm.enumInit
              ? _c("fieldForm", {
                  ref: "formRef",
                  attrs: { fields: _vm.fields },
                  on: { fieldChange: _vm.fieldChange },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticStyle: { height: "200px" } }),
      ]),
      _c("div", { staticStyle: { height: "100px" } }),
      _c("fixedButton", {
        attrs: { text: "提交" },
        on: { emitEvent: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }