<template>
  <div class="statistics">
    <searchFilter :searchForm="searchForm" @emitSearch="emitSearch" immediately v-if="initEnum" />
    <pull-refresh v-model="pullLoading" @refresh="refreshList">
      <div class="chart-item">
        <div class="chart-top">
          <p class="line-title">数据面板</p>
          <span class="chart-date">{{ showTime(maxDate) }}</span>
        </div>
        <panelTable v-if="panelInfo" :info="panelInfo" />
      </div>

      <div style="height: 40px" />
      <!-- 月度 -->
      <div class="chart-item" v-if="monthlyAnalysis.options" key="monthlyAnalysis">
        <div class="chart-top">
          <p class="line-title">{{ monthlyAnalysis.key }}</p>
          <span @click="showCalendar = true" class="chart-date">
            {{ showTime(currentDate, 'YYYY-MM') }} <Icon name="filter-o" />
          </span>
          <span @click="toTableMonth" class="chart-detail">数据明细<Icon name="arrow"/></span>
        </div>
        <div class="type-warp">
          <span
            :class="[monthlyAnalysis.activeTypes === item.value && 'active']"
            v-for="(item, index) in monthlyAnalysis.tabs"
            :key="index"
            @click="changeActive(item)"
          >
            {{ item.type }}
          </span>
        </div>

        <categoryChart :option="monthlyAnalysis.options" :last-start="monthlyAnalysis.activeTypes === 'date'" />
      </div>

      <div style="height: 40px" />
      <!-- 多维度 -->
      <div class="chart-item" v-if="timesAnalysis.options" key="timesAnalysis">
        <div class="chart-top">
          <p class="line-title">{{ timesAnalysis.key }}</p>
          <!-- <span @click="showCalendar = true" class="chart-date">
            {{ showTime(currentDate, 'YYYY-MM') }} <Icon name="filter-o" />
          </span> -->
          <span @click="toTableTime" class="chart-detail">数据明细<Icon name="arrow"/></span>
        </div>
        <div class="type-warp">
          <span
            :class="[timesAnalysis.activeTypes === item.value && 'active']"
            v-for="(item, index) in timesAnalysis.tabs"
            :key="index"
            @click="changeActive2(item)"
          >
            {{ item.type }}
          </span>
        </div>

        <categoryChart :option="timesAnalysis.options" :last-start="true" />
      </div>

      <div v-for="(item, index) in chartList" :key="index" class="chart-item">
        <p class="line-title">{{ item.key }}</p>
        <categoryChart :option="item.options" />
      </div>
    </pull-refresh>

    <div style="height: 200px;" />
    <tabbar :curIndex="1" />

    <popup v-model="showCalendar" position="bottom">
      <datetime-picker
        v-model="currentDate"
        @confirm="calendarConfirm"
        @cancel="showCalendar = false"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
      />
    </popup>

    <popup v-model="showYearWeek" position="bottom">
      <picker :columns="yearWeekList" @confirm="yearWeekConfirm" @cancel="showYearWeek = false" value-key="label" />
    </popup>
  </div>
</template>

<script>
import searchFilter from '@/components/searchFilter';
import services from '@/service';
import { Icon, PullRefresh } from 'vant';
import { routerPush } from '@/lib/utils';
import tabbar from '../components/tabbar';
import categoryChart from '@/components/echarts/categoryChart.vue';
import panelTable from './components/panelTable.vue';
import { Popup, DatetimePicker } from 'vant';
const dayjs = require('dayjs');
import Picker from '@/components/picker';
import { goodsSettingListAll } from '@/enumAdmin/index';

export default {
  name: 'adminStatistics',
  components: {
    searchFilter,
    // Collapse,
    // CollapseItem,
    Icon,
    PullRefresh,
    panelTable,
    tabbar,
    categoryChart,
    Popup,
    DatetimePicker,
    Picker
  },
  data() {
    return {
      pullLoading: false,
      showCalendar: false,
      showYearWeek: false,
      minDate: new Date(2024, 2, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      activeNames: [],
      search: '',
      routers: [],
      hasList: ['诊所审批', '医生审批', '检测管理'],
      chartList: [],
      monthlyAnalysis: {
        tabs: [
          { type: '日期分布', value: 'date' },
          { type: '合伙人分布', value: 'partner' },
          { type: '区域分布', value: 'city' },
          { type: '诊所分布', value: 'clinic' },
          { type: '诊所平均检测量', value: 'clinicAvg' },
          { type: '城市平均检测量', value: 'cityAvg' }
        ],
        activeTypes: 'date',
        activeDate: null,
        key: '日统计分析',
        options: null
      },
      timesAnalysis: {
        tabs: [
          { type: '周度分布', value: 'week' },
          { type: '月度分布', value: 'month' },
          { type: '年度分布', value: 'year' }
        ],
        activeTypes: 'week',
        activeDate: null,
        key: '时间维度统计分析',
        options: null
      },
      weekAnalysis: {
        tabs: [
          { type: '默认分布', value: 'default' },
          { type: '城市分布', value: 'city' }
        ],
        activeTypes: 'default',
        activeDate: {},
        key: '周度趋势分析',
        options: null
      },
      yearWeekList: [],
      panelInfo: null,
      settingList: {}
    };
  },
  computed: {
    searchForm() {
      return {
        goodsSettingId: {
          label: '产品',
          component: 'select',
          options: this.settingList.enums
        }
      };
    },
    initEnum() {
      return this.settingList.enums;
    }
  },
  watch: {
    routers: {
      handler() {
        this.activeNames = this.routers.map((item, index) => index);
      },
      immediate: true
    }
  },
  methods: {
    yearWeekConfirm(e) {
      this.weekAnalysis.activeDate = e;
      this.showYearWeek = false;
      this.getWeekAnalysis();
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    showTime(time, format = 'YYYY-MM-DD') {
      return dayjs(time).format(format);
    },
    calendarConfirm(time) {
      this.monthlyAnalysis.activeDate = dayjs(time).format('YYYY-MM');
      this.showCalendar = false;
      this.getMonthlyAnalysis();
    },
    changeActive(item) {
      this.monthlyAnalysis.activeTypes = item.value;
      this.getMonthlyAnalysis();
    },
    changeActive2(item) {
      this.timesAnalysis.activeTypes = item.value;
      this.getTimesAnalysis();
    },
    async toTableMonth() {
      routerPush({
        name: `adminStatisticsTable`,
        query: {
          queryType: 'getMonthlyAnalysis',
          showKey: 'month',
          month: this.monthlyAnalysis.activeDate.split('-').join(''),
          type: this.monthlyAnalysis.activeTypes
        }
      });
    },
    async toTableTime() {
      routerPush({
        name: `adminStatisticsTable`,
        query: {
          queryType: 'getTimesAnalysis',
          showKey: 'key',
          key: this.timesAnalysis.key,
          type: this.timesAnalysis.activeTypes
        }
      });
    },
    async monthlyStatistics() {
      alert(11);
      const res = await services.admin.monthlyStatistics();
      let options = {};
      if (this.monthlyAnalysis.activeTypes === 'clinicAvg') {
        alert(1);
        options = {
          dataset: {
            source: [
              ['月份', '诊所平均检测量'],
              ...res.data.map(i => {
                return [i.months, i.weightedAvgCheckCount];
              })
            ]
          }
        };
      } else {
        options = {
          dataset: {
            source: [
              ['月份', '检测量', '服务单量', '新诊所量', '设备签约量'],
              ...res.data.map(i => {
                return [i.months, i.checkCount, i.servicsCount, i.clinicCount, i.goodsSignCount];
              })
            ]
          }
        };
      }

      this.chartList.push({
        options,
        key: '近1年的月度趋势统计'
      });
    },
    async dataPanel() {
      const res = await services.admin.dataPanel(this.searchParams);
      this.panelInfo = res.data;
    },
    async getMonthlyAnalysis() {
      const res = await services.admin.monthlyAnalysis({
        month: this.monthlyAnalysis.activeDate.split('-').join(''),
        type: this.monthlyAnalysis.activeTypes,
        ...this.searchParams
      });
      let options = {};
      if (this.monthlyAnalysis.activeTypes === 'clinic') {
        options = {
          dataset: {
            source: [
              ['', '检测量', '服务单量', '设备签约量'],
              ...res.data.map(i => {
                return [i.typeValue, i.checkCount, i.servicsCount, i.goodsSignCount];
              })
            ]
          }
        };
      } else if (this.monthlyAnalysis.activeTypes === 'clinicAvg') {
        options = {
          dataset: {
            source: [
              ['', '诊所平均检测量'],
              ...res.data.map(i => {
                return [i.typeValue, i.weightedAvgCheckCount];
              })
            ]
          }
        };
      } else if (this.monthlyAnalysis.activeTypes === 'cityAvg') {
        options = {
          dataset: {
            source: [
              ['', '城市平均检测量'],
              ...res.data.map(i => {
                return [i.typeValue, i.weightedAvgCheckCount];
              })
            ]
          }
        };
      } else {
        options = {
          dataset: {
            source: [
              ['', '检测量', '服务单量', '新诊所量', '设备签约量'],
              ...res.data.map(i => {
                return [i.typeValue, i.checkCount, i.servicsCount, i.clinicCount, i.goodsSignCount];
              })
            ]
          }
        };
      }

      this.monthlyAnalysis.options = options;
    },
    async getTimesAnalysis() {
      const res = await services.admin.timesAnalysis({
        type: this.timesAnalysis.activeTypes,
        ...this.searchParams
      });
      let options = {};

      options = {
        dataset: {
          source: [
            ['', '检测量', '服务单量', '新诊所量', '设备签约量', '服务复购单量'],
            ...res.data.map(i => {
              return [i.typeValue, i.checkCount, i.servicsCount, i.clinicCount, i.goodsSignCount, i.reServicsCount];
            })
          ]
        }
      };

      this.timesAnalysis.options = options;
    },
    async getWeekAnalysis() {
      const res = await services.admin.weekAnalysis({
        weekNumber: this.weekAnalysis.activeDate.weekNumber,
        type: this.weekAnalysis.activeTypes
      });
      let options = {};
      options = {
        dataset: {
          source: [
            ['', '检测量', '服务单量', '新诊所量', '设备签约量', '服务复购单量'],
            ...res.data.map(i => {
              return [i.typeValue, i.checkCount, i.servicsCount, i.clinicCount, i.goodsSignCount, i.reServicsCount];
            })
          ]
        }
      };

      this.weekAnalysis.options = options;
    },
    toPage(path) {
      this.$router.push({
        path
      });
    },
    async refreshList() {
      this.chartList = [];
      this.maxDate = new Date();
      await this.dataPanel();
      await this.calendarConfirm(new Date());
      await this.getTimesAnalysis();

      this.pullLoading = false;
    },
    emitSearch(params) {
      this.searchParams = params;
      this.refreshList();
    }
  },

  async mounted() {
    const [settingList] = await Promise.all([goodsSettingListAll()]);

    this.settingList = settingList;
    console.log(this.settingList.enums);
    // this.refreshList();
  }
};
</script>

<style lang="scss" scoped>
.statistics {
  background: #fff;

  .chart-item {
    padding: $tb $lr;
    // margin-bottom: 20px;
    .line-title {
      margin-bottom: $tb;
    }
    .chart-date {
      color: $gray1;
      font-size: $size6;
    }
    .chart-detail {
      color: $appcolor;
      font-size: $size6;
    }
    .chart-top {
      @include flex(row);
      justify-content: space-between;
    }

    .time-tip {
      font-size: $size6;
      color: $gray2;
      text-align: center;
      margin: 0 0 10px;
      color: #ed6a0c;
    }

    .type-warp {
      @include flex(row);
      justify-content: space-around;
      color: $gray2;
      padding: 4px 0;
      font-size: $size6;
      margin-bottom: 10px;
      span {
        padding: 10px;
        transition-duration: 0.3s;
        border-radius: 4px;
        &.active {
          background: lighten($appcolor, 50%);
          color: $appcolor;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
